import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteregistro.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3542602',
  schema: 'pbi_dte_registro',
  groupId: 'e7df04ba-03b2-44ea-ad49-cf9d81cebb35',
  analiseGeral: 'c2682843-d36c-4c97-9e3e-d8ed441e1c38',
  analiseUsuarios: '1dc45739-9a62-49bc-931d-e71ddfea3f0e',
  analiseComunicados: 'ff26d665-6edd-46d0-beba-5e6a8d8bc570',
  analiseProcurador: '34b2a3a8-c4af-4a4e-b999-791f402d2141',
  analiseEventos: '194dc11b-a74a-49ae-a93a-eee962d9c189',
  analiseContribuintes: 'd747402f-d061-4085-b8b6-10967428bef7'
};
